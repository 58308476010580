import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import UniGraphSettings from '../../../pages/dashboard/modules/kpi-dashboard/models/unigraph-settings';
import { config, Subscription } from 'rxjs';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { PrimeIcons } from 'primeng/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'uni-graph',
    templateUrl: './uni-graph.component.html',
    animations: [
        trigger('toggle', [
            state(
                'open',
                style({
                    opacity: 1,
                    transform: 'translateY(0%)',
                })
            ),
            state(
                'close',
                style({
                    opacity: 0,
                    transform: 'translateY(100%)',
                })
            ),
            transition('close => open', [animate('0.2s 100ms ease-in-out')]),
            transition('open => close', [animate('0.2s 100ms ease-in-out')]),
        ]),
    ],
})
export class UniGraphComponent implements OnInit, OnDestroy, OnChanges {
    @Input() settings: UniGraphSettings;

    protected primaryColor: string;
    protected primaryLightColor: string;
    protected secondaryTextColor: string;
    protected Icons = PrimeIcons;
    protected showInfo = false;

    protected subscriptions: Subscription[] = [];
    protected lineChart = false;

    protected readonly config = config;

    constructor(private layoutService: LayoutService) {
        this.subscriptions.push(this.layoutService.configUpdate$.subscribe(() => this.updateColors()));
    }

    ngOnInit(): void {
        this.updateColors();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateColors();
    }

    updateColors() {
        let style = getComputedStyle(document.body);
        this.primaryColor = style.getPropertyValue('--primary-color');
        this.primaryLightColor = style.getPropertyValue('--primary-lighter-color');
        this.secondaryTextColor = style.getPropertyValue('--text-color-secondary');
        this.settings = {
            ...this.settings,
            datasets: this.settings.datasets.map((d) => ({
                ...d,
                backgroundColor: this.primaryColor,
                borderColor: this.primaryColor,
            })),
        };
    }

    toggleInfo() {
        this.showInfo = !this.showInfo;
    }
}
