<!-- <div class="surface-0 overflow-hidden relative">

  <div class="demo-ribbon">DEMO</div>
  
  <div
    class="flex align-items-center justify-content-between flex-column h-screen"
  >
    <div
      class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-4"
    >
    <a href="https://www.insel.ch" target="_blank" class="mb-4">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1000 328"
        style="enable-background: new 0 0 1000 328"
        xml:space="preserve"
        width="100%"
      >
        <style type="text/css">
          .st0 {
            fill: var(--gray-400);
          }
          .st1 {
            fill: var(--primary-400);
          }
        </style>
        <polygon
          class="st0"
          points="458.3,236.7 458.4,138.5 477.2,138.5 477.4,220.2 515.4,220.2 515.4,236.7 "
        />
        <polygon
          class="st0"
          points="374,236.7 374.2,138.5 427.1,138.5 427.1,155.1 392.6,155.1 392.4,178.9 426.6,178.9 426.6,194.5 
                      392.8,194.5 392.6,220.2 427.1,220.2 427.1,236.7 "
        />
        <polygon
          class="st0"
          points="181.4,236.7 181.3,137.9 201.2,137.9 244.5,209.9 244.7,138.5 262.4,138.5 262.4,237.2 241.7,237.2 
                      199.1,165.7 199.3,236.7 "
        />
        <polygon
          class="st0"
          points="131.7,236.7 131.8,138.5 150.6,138.5 150.7,236.7 "
        />
        <path
          class="st1"
          d="M52.6,206.7h-7.4v-82.9c0-16-18.7-26.8-26-28.9L12.8,105c3.7,1.4,17.2,6.2,17.2,18.4v72.2
                      c-14-9.9-24-22-24-38.8V90h93.3v66.8c0,16.8-10,28.9-23.9,38.8v-72.3c0-12.3,13.4-17,17.1-18.4L86,94.8c-7.3,2.2-26,13-26,28.9v82.9
                      L52.6,206.7z"
        />
        <path
          class="st0"
          d="M334.5,138.6c4.6,1.3,9,3.2,13.1,5.6l0.4,0.3l-0.3,0.5l-7,13.7l-0.3,0.5l-0.5-0.3c-2.8-1.9-5.9-3.5-9.1-4.7
                      c-2.5-1-5.1-1.6-7.8-1.8c-4-0.2-7.8,1.1-10.9,3.7c-2.7,2.5-4.3,6-4.2,9.7c0.1,8,7.4,11.1,15.7,14.6c11.2,4.7,24.1,10.3,24.1,29.2
                      v0.2l0,0c0,7.7-3.3,15-9.1,20.1c-6.6,5.5-14.9,8.5-23.5,8.2h-0.3c-5.8-0.1-11.5-1.2-16.9-3c-5-1.7-9.8-3.9-14.3-6.5l-0.5-0.3
                      l0.3-0.5l8-13.6l0.3-0.4l0.5,0.3c0.7,0.4,1.6,0.9,2.4,1.3c5.7,3.8,12.2,6.3,18.9,7.2c4,0.1,7.9-1.1,11.2-3.5
                      c2.7-2.1,4.2-5.4,4.1-8.9c0-8-7.7-11.4-16.4-15.3c-11.4-5.1-24.3-10.8-24.3-28.4c-0.1-8.1,3.3-15.8,9.3-21.2
                      c6.6-5.6,15-8.6,23.6-8.5C325.5,136.8,330,137.4,334.5,138.6"
        />
        <path
          class="st1"
          d="M537.5,230.5c7.9,5.2,17.2,7.9,26.6,7.7c21.3,0,31.4-13.5,31.4-27.9c0-30.4-43.6-25.6-43.6-46.9
                      c-0.1-9.3,7.5-17,16.8-17c0.4,0,0.7,0,1.1,0c7.5,0.1,14.7,2.6,20.6,7.2l4.6-8.9c-7.8-4.9-16.7-7.5-25.8-7.6
                      c-17.2,0-29.5,10.5-29.5,25.5c0,34,42.8,23.9,42.8,48.5c0.2,9.6-7.4,17.5-17,17.7c-0.7,0-1.5,0-2.2-0.1c-8.5-0.8-16.6-3.8-23.5-8.7
                      l-5.3,8.7L537.5,230.5z"
        />
        <path
          class="st1"
          d="M994,236.2v-10.1h-40.8l0.3-87h-12v97.1L994,236.2L994,236.2z M888.3,193.9h-31.9l16.3-43.8h0.3L888.3,193.9z
                      M826.1,236.3h12.5l13.6-32.4h40.1l12.5,32.4h13.5L879,139.2h-11.5L826.1,236.3z M750.3,149.2H781l0.1,87h11.7l0.2-87h30.7v-10.1
                      h-73.5L750.3,149.2L750.3,149.2z M713,236.2h12.1l0.1-97.1h-12L713,236.2z M635.8,236.2l0.1-40.1h13.9c16,0,34.5-7.4,34.5-29
                      c0-18.5-12.4-27.9-30.8-27.9h-29.3v97.1H635.8z M636,147.5h14.6c15.7,0,21.6,9.1,21.6,19.5c0.3,10.7-8.1,19.5-18.8,19.8
                      c-0.8,0-1.5,0-2.3-0.1h-15.4L636,147.5z"
        />
      </svg>
    </a>
      <div class="flex flex-column">
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-envelope"></i>
          <input
            id="email"
            [(ngModel)]="email"
            type="text"
            pInputText
            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
            placeholder="Email"
            (keyup)="onUserInput($event)"
          />
        </span>
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-lock"></i>
          <input
            id="password"
            [(ngModel)]="password"
            type="password"
            pInputText
            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
            placeholder="Passwort"
            (keyup)="onUserInput($event)"
          />
        </span>

        <button
          pButton
          pRipple
          label="Anmelden"
          class="w-full mb-4"
          (click)="login()"
        ></button>

        <span class="text-red-500 mb-2">
          {{ error }}
        </span>

        <a href="#" class="font-medium text-sm text-300">Passwort vergessen?</a>
        <p class="font-medium text-400 m-0 mt-6">Don’t you have an account, <a href="#" class="text-primary cursor-pointer">sign up</a></p>
      </div>
    </div>
      <a class="flex flex-wrap align-items-center pb-8 px-4" href="https://www.prognosix.ch/" target="_blank">
        <h6 class="m-0 mt-1 text-300 font-medium mr-2">Copyright Ⓒ </h6>
        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 493.33 493.29" class="mr-2" style="height: 2rem;">
            <defs><style>.prognosix-logo-1-st0{fill:url(#Unbenannter_Verlauf_3);}</style>
            <defs><style>.prognosix-logo-1-st0{fill:var(--primary-400)}</style>
            <defs><style>.prognosix-logo-1-st0{fill:var(--text-color-secondary)}</style>
            <linearGradient id="Unbenannter_Verlauf_3" x1="50.97" y1="304.57" x2="544.3" y2="304.57" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#0d955e"/>
                <stop offset="0.31" stop-color="#3ca449"/>
                <stop offset="0.78" stop-color="#7eba2c"/>
                <stop offset="1" stop-color="#98c220"/>
            </linearGradient></defs>
            <title>Prognosix_Zeichen</title>
            <path class="prognosix-logo-1-st0" d="M537.74,58.09A8.18,8.18,0,0,0,528.6,63,875.94,875.94,0,0,1,385.46,296.64C322.77,310.72,266,339.85,216,375.79q-52.56-5.28-90.12,14.54c-78.16,41.48-75,148.5-74.8,153,0,.17.06.33.08.5s.05.37.09.56a8.07,8.07,0,0,0,.87,2.61c0,.06.09.12.13.18a8.12,8.12,0,0,0,1.83,2.1c.08.06.11.16.19.22s.1,0,.15.08.11.11.17.15a8.17,8.17,0,0,0,1.9.95c.2.07.41.11.62.16a8.07,8.07,0,0,0,1.72.28c.14,0,.27.07.41.07s.26,0,.4,0a10.08,10.08,0,0,0,1.83-.3c.12,0,.24,0,.35-.07,1.88-.62,110-37.85,230.76-139.23,79.25,29.09,166.19,84.76,238.78,138.07a8.16,8.16,0,0,0,13-6.58v-477A8.16,8.16,0,0,0,537.74,58.09ZM528,294.9c-18.69-4.67-60.37-11.78-117.34-3A889.31,889.31,0,0,0,528,104.82ZM133.49,404.75c17.81-9.45,38.26-13.66,60.57-13.66.48,0,1,0,1.47,0-50.11,39.22-92.72,84.53-126.8,126.64C72.46,485.37,85.85,430,133.49,404.75ZM84.77,523.92c36.15-44.16,81.71-91.55,135.38-131a311,311,0,0,1,54.38,12.5C195.38,470.39,122.91,507.1,84.77,523.92Zm204.7-131.08a367.35,367.35,0,0,0-49.94-13.58c38.52-26,80.86-47.47,126.59-60.79A885,885,0,0,1,289.47,392.84Zm17.45,6.39a899.09,899.09,0,0,0,87.27-87.76c68.63-14.37,118.11-3.95,133.79.34V527Q401.75,435.93,306.92,399.23Z" transform="translate(-50.97 -57.93)"/>
        </svg>
        <h6 class="m-0 mt-1 text-300 font-medium">PROGNOSIX AG</h6>
    </a>
  </div>
</div>

<app-config [minimal]="true"></app-config> -->

<div class="overflow-hidden relative background-image">
    <!-- <div class="demo-ribbon">DEMO</div> -->

    <div class="flex align-items-center justify-content-between flex-column h-screen">
        <div
            class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-0 sm:px-4"
        >
            <div class="card p-3 sm:p-4 md:p-5 lg:p-7 shadow-3">
                <a
                    href="https://www.insel.ch"
                    target="_blank"
                    class="mb-4"
                >
                    <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1000 328"
                        style="enable-background: new 0 0 1000 328"
                        xml:space="preserve"
                        width="100%"
                    >
                        <style type="text/css">
                            .st0 {
                                fill: var(--gray-400);
                            }
                            .st1 {
                                fill: var(--primary-400);
                            }
                        </style>
                        <polygon
                            class="st0"
                            points="458.3,236.7 458.4,138.5 477.2,138.5 477.4,220.2 515.4,220.2 515.4,236.7 "
                        />
                        <polygon
                            class="st0"
                            points="374,236.7 374.2,138.5 427.1,138.5 427.1,155.1 392.6,155.1 392.4,178.9 426.6,178.9 426.6,194.5 
                        392.8,194.5 392.6,220.2 427.1,220.2 427.1,236.7 "
                        />
                        <polygon
                            class="st0"
                            points="181.4,236.7 181.3,137.9 201.2,137.9 244.5,209.9 244.7,138.5 262.4,138.5 262.4,237.2 241.7,237.2 
                        199.1,165.7 199.3,236.7 "
                        />
                        <polygon
                            class="st0"
                            points="131.7,236.7 131.8,138.5 150.6,138.5 150.7,236.7 "
                        />
                        <path
                            class="st1"
                            d="M52.6,206.7h-7.4v-82.9c0-16-18.7-26.8-26-28.9L12.8,105c3.7,1.4,17.2,6.2,17.2,18.4v72.2
                        c-14-9.9-24-22-24-38.8V90h93.3v66.8c0,16.8-10,28.9-23.9,38.8v-72.3c0-12.3,13.4-17,17.1-18.4L86,94.8c-7.3,2.2-26,13-26,28.9v82.9
                        L52.6,206.7z"
                        />
                        <path
                            class="st0"
                            d="M334.5,138.6c4.6,1.3,9,3.2,13.1,5.6l0.4,0.3l-0.3,0.5l-7,13.7l-0.3,0.5l-0.5-0.3c-2.8-1.9-5.9-3.5-9.1-4.7
                        c-2.5-1-5.1-1.6-7.8-1.8c-4-0.2-7.8,1.1-10.9,3.7c-2.7,2.5-4.3,6-4.2,9.7c0.1,8,7.4,11.1,15.7,14.6c11.2,4.7,24.1,10.3,24.1,29.2
                        v0.2l0,0c0,7.7-3.3,15-9.1,20.1c-6.6,5.5-14.9,8.5-23.5,8.2h-0.3c-5.8-0.1-11.5-1.2-16.9-3c-5-1.7-9.8-3.9-14.3-6.5l-0.5-0.3
                        l0.3-0.5l8-13.6l0.3-0.4l0.5,0.3c0.7,0.4,1.6,0.9,2.4,1.3c5.7,3.8,12.2,6.3,18.9,7.2c4,0.1,7.9-1.1,11.2-3.5
                        c2.7-2.1,4.2-5.4,4.1-8.9c0-8-7.7-11.4-16.4-15.3c-11.4-5.1-24.3-10.8-24.3-28.4c-0.1-8.1,3.3-15.8,9.3-21.2
                        c6.6-5.6,15-8.6,23.6-8.5C325.5,136.8,330,137.4,334.5,138.6"
                        />
                        <path
                            class="st1"
                            d="M537.5,230.5c7.9,5.2,17.2,7.9,26.6,7.7c21.3,0,31.4-13.5,31.4-27.9c0-30.4-43.6-25.6-43.6-46.9
                        c-0.1-9.3,7.5-17,16.8-17c0.4,0,0.7,0,1.1,0c7.5,0.1,14.7,2.6,20.6,7.2l4.6-8.9c-7.8-4.9-16.7-7.5-25.8-7.6
                        c-17.2,0-29.5,10.5-29.5,25.5c0,34,42.8,23.9,42.8,48.5c0.2,9.6-7.4,17.5-17,17.7c-0.7,0-1.5,0-2.2-0.1c-8.5-0.8-16.6-3.8-23.5-8.7
                        l-5.3,8.7L537.5,230.5z"
                        />
                        <path
                            class="st1"
                            d="M994,236.2v-10.1h-40.8l0.3-87h-12v97.1L994,236.2L994,236.2z M888.3,193.9h-31.9l16.3-43.8h0.3L888.3,193.9z
                        M826.1,236.3h12.5l13.6-32.4h40.1l12.5,32.4h13.5L879,139.2h-11.5L826.1,236.3z M750.3,149.2H781l0.1,87h11.7l0.2-87h30.7v-10.1
                        h-73.5L750.3,149.2L750.3,149.2z M713,236.2h12.1l0.1-97.1h-12L713,236.2z M635.8,236.2l0.1-40.1h13.9c16,0,34.5-7.4,34.5-29
                        c0-18.5-12.4-27.9-30.8-27.9h-29.3v97.1H635.8z M636,147.5h14.6c15.7,0,21.6,9.1,21.6,19.5c0.3,10.7-8.1,19.5-18.8,19.8
                        c-0.8,0-1.5,0-2.3-0.1h-15.4L636,147.5z"
                        />
                    </svg>
                </a>
                <div class="flex flex-column">
                    <span class="p-input-icon-left w-full mb-4">
                        <i class="pi pi-envelope"></i>
                        <input
                            id="email"
                            [(ngModel)]="email"
                            type="text"
                            pInputText
                            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
                            placeholder="Email"
                            (keyup)="onUserInput($event)"
                        />
                    </span>
                    <span class="p-input-icon-left w-full mb-4">
                        <i class="pi pi-lock"></i>
                        <input
                            id="password"
                            [(ngModel)]="password"
                            type="password"
                            pInputText
                            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
                            placeholder="Passwort"
                            (keyup)="onUserInput($event)"
                        />
                    </span>

                    <button
                        pButton
                        pRipple
                        label="Anmelden"
                        class="w-full mb-4"
                        (click)="login()"
                    ></button>

                    <span class="text-red-500 mb-2">
                        {{ error }}
                    </span>

                    <a
                        href="#"
                        class="font-medium text-sm text-300"
                        >Passwort vergessen?</a
                    >
                </div>
            </div>
        </div>

        <div class="w-full footer-gradient h-3rem flex justify-content-center pt-2">
            <a
                class="flex flex-wrap align-items-center pb-8 px-4"
                href="https://www.prognosix.ch/"
                target="_blank"
            >
                <h6 class="m-0 mt-1 text-300 font-medium mr-2">Copyright Ⓒ</h6>
                <svg
                    id="Ebene_1"
                    data-name="Ebene 1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 493.33 493.29"
                    class="mr-2"
                    style="height: 2rem"
                >
                    <defs>
                        <style>
                            .prognosix-logo-1-st0 {
                                fill: url(#Unbenannter_Verlauf_3);
                            }
                        </style>
                        <linearGradient
                            id="Unbenannter_Verlauf_3"
                            x1="50.97"
                            y1="304.57"
                            x2="544.3"
                            y2="304.57"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop
                                offset="0"
                                stop-color="#0d955e"
                            />
                            <stop
                                offset="0.31"
                                stop-color="#3ca449"
                            />
                            <stop
                                offset="0.78"
                                stop-color="#7eba2c"
                            />
                            <stop
                                offset="1"
                                stop-color="#98c220"
                            />
                        </linearGradient>
                    </defs>
                    <title>Prognosix_Zeichen</title>
                    <path
                        class="prognosix-logo-1-st0"
                        d="M537.74,58.09A8.18,8.18,0,0,0,528.6,63,875.94,875.94,0,0,1,385.46,296.64C322.77,310.72,266,339.85,216,375.79q-52.56-5.28-90.12,14.54c-78.16,41.48-75,148.5-74.8,153,0,.17.06.33.08.5s.05.37.09.56a8.07,8.07,0,0,0,.87,2.61c0,.06.09.12.13.18a8.12,8.12,0,0,0,1.83,2.1c.08.06.11.16.19.22s.1,0,.15.08.11.11.17.15a8.17,8.17,0,0,0,1.9.95c.2.07.41.11.62.16a8.07,8.07,0,0,0,1.72.28c.14,0,.27.07.41.07s.26,0,.4,0a10.08,10.08,0,0,0,1.83-.3c.12,0,.24,0,.35-.07,1.88-.62,110-37.85,230.76-139.23,79.25,29.09,166.19,84.76,238.78,138.07a8.16,8.16,0,0,0,13-6.58v-477A8.16,8.16,0,0,0,537.74,58.09ZM528,294.9c-18.69-4.67-60.37-11.78-117.34-3A889.31,889.31,0,0,0,528,104.82ZM133.49,404.75c17.81-9.45,38.26-13.66,60.57-13.66.48,0,1,0,1.47,0-50.11,39.22-92.72,84.53-126.8,126.64C72.46,485.37,85.85,430,133.49,404.75ZM84.77,523.92c36.15-44.16,81.71-91.55,135.38-131a311,311,0,0,1,54.38,12.5C195.38,470.39,122.91,507.1,84.77,523.92Zm204.7-131.08a367.35,367.35,0,0,0-49.94-13.58c38.52-26,80.86-47.47,126.59-60.79A885,885,0,0,1,289.47,392.84Zm17.45,6.39a899.09,899.09,0,0,0,87.27-87.76c68.63-14.37,118.11-3.95,133.79.34V527Q401.75,435.93,306.92,399.23Z"
                        transform="translate(-50.97 -57.93)"
                    />
                </svg>
                <h6 class="m-0 mt-1 text-300 font-medium">PROGNOSIX AG</h6>
            </a>
        </div>
    </div>
</div>

<app-config [minimal]="true"></app-config>
