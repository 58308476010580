import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-smart-loader',
    templateUrl: './smart-loader.component.html',
    styleUrls: ['./smart-loader.component.scss'],
})
export class SmartLoaderComponent implements OnInit {
    loading: boolean = false;

    value: number = 0;

    // //funny waiting phrases
    // defaultPhrases = [
    //   'Getting things ready...',
    //   'Loading the universe...',
    //   'Loading the world...',
    //   'Spending quality time with your data...',
    //   'Calculating the answer to life, the universe and everything...',
    //   'Doing the impossible...',
    //   'Calculating the speed of light...',
    //   'Doing the math...',
    //   'Analyzing the meaning of life...',
    //   'Doing AI stuff...',
    //   'Waiting for the server...',
    //   'Preparing the world for you...',
    //   'Cooking the data...',
    // ];
    //funny waiting phrases
    defaultPhrases = [
        'Lade Bestelldaten...',
        'Lade aktuelle Bestellvorschläge...',
        'Lade Snapshot Bestellvorschläge...',
        'Lade Kundenbestellungen...',
        'Lade Kundenbedarfsprognosen...',
        'Lade Bestandsdaten...',
        'Lade Wareneingangsdaten...',
        'Berechne Abweichungen',
        'Berechne OTIF...',
        'Filtere Daten...',
        'Wende künstliche Intelligenz an...',
        'Finde den Sinn des Lebens...',
        'Verbessere die Welt...',
    ];

    shownPhrase: string = '';

    //variable for setInterval
    interval: any;

    constructor() {}

    ngOnInit(): void {}

    startFakeLoading(forTime: number, showPhrases: boolean = false, phrases: string[] = this.defaultPhrases) {
        this.loading = true;

        if (showPhrases) {
            this.startShowingPhrases(phrases);
        }

        let steps = 10;

        this.value = 0;

        for (let step = 1; step <= steps; step++) {
            setTimeout(
                () => {
                    if (this.value >= 100) return;

                    if (step == steps) {
                        this.value = 100;
                        return;
                    }

                    let increment = Math.floor(Math.random() * (100 / steps) + 100 / steps / 1.5);

                    if (this.value + increment > 100) {
                        this.value = 100;
                    } else this.value += increment;
                },
                (step * forTime) / steps,
            );
        }
    }

    stopFakeLoading(timeout: number = 1000) {
        this.value = 100;

        setTimeout(() => {
            this.loading = false;

            if (this.interval) {
                clearInterval(this.interval);
                this.interval = undefined;
            }
        }, timeout);
    }

    startShowingPhrases(phrases: string[] = this.defaultPhrases) {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
        }

        phrases = phrases.sort(() => Math.random() - 0.5);

        this.shownPhrase = phrases[0];

        let i = 1;

        this.interval = setInterval(() => {
            this.shownPhrase = phrases[i];

            i++;

            if (i == phrases.length) i = 0;
        }, 4000);
    }
}
