import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AempService {
    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
    ) {}
}
