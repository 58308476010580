import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    socket: Socket<any, any> | undefined;
    message: BehaviorSubject<any> = new BehaviorSubject(undefined);
    lastMessage: any | undefined;
    constructor() {}

    stopSocket() {
        if (this.socket) this.socket.disconnect();
    }

    startSocket(id: number) {
        let ready = false;
        if (!this.socket) {
            this.socket = io(environment.socketUrl).connect();

            const result = new Observable((observer) => {
                this.socket?.on('connect', function () {
                    ready = true;
                    observer.next('done');
                });
            });

            result.subscribe((result) => {
                this.socket?.emit('login', { username: id, channel: id });
            });
        } else {
            this.socket.emit('login', { username: id, channel: id });
        }
        this.socket.on('message', (data: any) => {
            if (!data) return;
            if (!data.task_finished) return;
            this.message.next(data);
            this.lastMessage = data;
        });
    }
}
