import { Component } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AuthService } from '../../services/auth.service';
import { AempService } from 'src/app/shared/services/aemp.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    email: string = '';
    password: string = '';
    error: string | undefined = undefined;
    loading: boolean = false;

    rememberMe: boolean = false;

    constructor(
        private layoutService: LayoutService,
        private authService: AuthService,
        private aempService: AempService,
    ) {}

    ngOnInit(): void {}

    login() {
        this.loading = true;
        this.authService.login(this.email, this.password).subscribe({
            error: (error) => {
                this.loading = false;
                this.error = 'Incorrect email or password!';
                this.password = '';
            },
        });
    }

    onUserInput({ code }: { code: string }) {
        if (code == 'Enter') this.login();
    }

    get dark(): boolean {
        return this.layoutService.config.colorScheme !== 'light';
    }
}
