import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { CookieService } from 'ngx-cookie-service';
import { homePages } from '../models/homePages';
import { allowedRoutes } from '../models/allowedRoutes';
import { menuItems } from '../models/menuitems';
import { CachingService } from 'src/app/shared/services/caching.service';
import { AempService } from 'src/app/shared/services/aemp.service';
import { SocketService } from './socket.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    accessToken: string | undefined = undefined;

    user: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private cachingService: CachingService,
        private aempService: AempService,
        private socketService: SocketService,
    ) {
        this.accessToken = cookieService.get('accessToken');
    }

    /**
     * Tries to login the user then gets and saves the user data in the user variable and the cookie
     * @param {string} email - email of the user
     * @param {string} password - password of the user
     * @returns {Observable} - returns an error if the login failed
     */
    login(email: string, password: string) {
        if (email == 'a') {
            email = 'admin@prognosix.ch';
            password = '3?RnNuMGfT3mftZ#';
        }

        return new Observable((subscriber) => {
            this.http.post<any>(`${environment.apiUrl}/login`, { email, password }).subscribe({
                next: (response) => {
                    this.accessToken = response.message;
                    this.cookieService.set('accessToken', this.accessToken!);
                    this.getUserData()
                        .then(() => {
                            this.goToHomePage();
                        })
                        .catch((error) => {
                            subscriber.error(error);
                        });
                },
                error: (error) => subscriber.error(error),
            });
        });
    }

    async getUser() {
        if (this.user.getValue() != undefined) return this.user.getValue();

        await this.getUserData();

        return this.user.getValue();
    }

    async getUserData(): Promise<User | undefined> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.apiUrl}/users/me`).subscribe({
                next: (message) => {
                    let user: User = message;

                    this.socketService.startSocket(user.user_id);

                    let homePage: any = undefined;

                    homePages.forEach((h) => {
                        if (homePage) return;

                        user?.permission_keys.forEach((p) => {
                            if (homePage) return;
                            if (h.requiredPermission == p) homePage = h.homePage;
                        });
                    });

                    if (!homePage) homePage = '404';

                    user.homePage = homePage;

                    user.allowedRoutes = [];
                    user.menuItems = [];

                    user?.permission_keys.forEach((p) => {
                        user.allowedRoutes.push(
                            ...allowedRoutes.filter((r) => r.requiredPermission == p).map((r) => r.route),
                        );
                        //user.menuItems.push(...menuItems.filter(m => m.requiredPermission == p))
                    });

                    menuItems.forEach((m) => {
                        let disabled = true;

                        user.permission_keys.forEach((p) => {
                            if (m.requiredPermission == p) disabled = false;
                            //if (m.requiredPermission == p) user.menuItems.push(m);
                        });

                        m.disabled = disabled;
                    });

                    user.menuItems = menuItems;

                    // setTimeout(() => {
                    //   this.accessToken = 'lol';
                    // }, 3000);

                    this.user.next(user);

                    resolve(user);
                },
                error: (error) => {
                    this.user.next(undefined);
                    this.accessToken = undefined;
                    this.cookieService.delete('accessToken');

                    reject(undefined);
                },
            });
        });
    }

    // async getUserData(): Promise<User | undefined>{

    //   return new Promise((resolve, reject) => {
    //     let user = new User();
    //     user.active = true;
    //     user.canton = "";
    //     user.city = "";
    //     user.count_fruit = true;
    //     user.country = "";
    //     user.email = "";
    //     user.first_name = "Name";
    //     user.forecasting_include = false;
    //     user.id = 1;
    //     user.is_bio = false;
    //     user.last_name = "LastName";
    //     user.region = "";
    //     user.role = defaultRole;
    //     user.street = "";
    //     user.zip_code = "";

    //     this.user.next(user);

    //     resolve(user);
    //   });

    // }

    /**
     * Logges out the user by sending the logout request then deleting the user data from the cookie
     * then redirects to login
     */
    logout() {
        this.cachingService.deleteAll();

        if (!this.accessToken) {
            this.accessToken = undefined;
            this.cookieService.delete('accessToken');
            this.router.navigate(['/login']);
            return;
        }

        this.http.post<any>(`${environment.apiUrl}/logout`, {}).subscribe({
            next: (response) => {
                this.accessToken = undefined;
                this.cookieService.delete('accessToken');
                this.user.next(undefined);
                this.router.navigate(['/login']);
            },
            error: (error) => {
                console.error(error);
                this.accessToken = undefined;
                this.cookieService.delete('accessToken');
                this.user.next(undefined);
                this.router.navigate(['/login']);
            },
        });
    }

    /**
     * Redirects to the home page base on the user`s role
     */
    goToHomePage() {
        const user = this.user.getValue();
        // let socket = io('https://insel-scm-beta-api.prognosix.ch').connect();
        // socket.on('connect', function () {
        //   console.log('WebSocket connected ja dolazim iz auth servisa');
        //   // send a test message to the server
        //   socket.emit('test message', 'Hello, server!');
        // });

        if (!user) {
            this.router.navigate(['/login']);
            return;
        }

        this.router.navigate([`/${user?.homePage}`]);
    }
}
