<div>
    <div class="card">
        <div class="text-900 font-bold text-xl mb-3">Frequently Asked Questions</div>
        <p class="text-600 line-height-3">
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.
        </p>
    </div>

    <div class="flex flex-column md:flex-row gap-5">
        <div class="card mb-0 md:w-20rem">
            <div class="text-900 block font-bold mb-3">Categories</div>
            <ul class="list-none m-0 p-0">
                <li
                    pRipple
                    *ngFor="let item of items; let i = index"
                    (click)="changeItem(i)"
                    class="mb-2"
                >
                    <a
                        class="flex align-items-center cursor-pointer select-none p-3 transition-colors transition-duration-150 border-round"
                        [ngClass]="{ 'bg-primary': activeIndex === i, 'hover:surface-hover': activeIndex !== i }"
                    >
                        <i
                            [class]="item.icon"
                            class="mr-2 text-lg"
                        ></i>
                        <span>{{ item.label }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="card flex-1">
            <p-accordion>
                <p-accordionTab
                    *ngFor="let question of items[activeIndex].questions"
                    [header]="question"
                >
                    <p class="line-height-3 m-0 p-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
