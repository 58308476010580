import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ChartModule } from 'primeng/chart';
import { InputNumberModule } from 'primeng/inputnumber';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';
import { ProgressBarModule } from 'primeng/progressbar';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { SliderModule } from 'primeng/slider';
import { InplaceModule } from 'primeng/inplace';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { TranslateModule } from '@ngx-translate/core';

import { AppConfigModule } from 'src/app/layout/config/app.config.module';
import { LoaderComponent } from './loader/loader.component';
import { SmartLoaderComponent } from './components/smart-loader/smart-loader.component';
import { CellEditorModalComponent } from './components/cell-editor-modal/cell-editor-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { TagModule } from 'primeng/tag';
import { MultiSelectModule } from 'primeng/multiselect';
import { KnobModule } from 'primeng/knob';
import { AutoFocusModule } from 'primeng/autofocus';
import { SidebarModule } from 'primeng/sidebar';
import { SpeedDialModule } from 'primeng/speeddial';
import { FloatLabelModule } from 'primeng/floatlabel';
import { UniGraphComponent } from './components/uni-graph/uni-graph.component';

@NgModule({
    declarations: [LoaderComponent, SmartLoaderComponent, CellEditorModalComponent, ModalComponent, UniGraphComponent],
    imports: [
        CommonModule,
        ButtonModule,
        CheckboxModule,
        PasswordModule,
        FormsModule,
        AccordionModule,
        InputTextModule,
        AppConfigModule,
        SelectButtonModule,
        TranslateModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        ProgressSpinnerModule,
        ChartModule,
        InputNumberModule,
        SkeletonModule,
        TableModule,
        DividerModule,
        ProgressBarModule,
        PanelModule,
        DialogModule,
        ToastModule,
        SliderModule,
        InplaceModule,
        ConfirmPopupModule,
        TagModule,
        MultiSelectModule,
        KnobModule,
        OverlayPanelModule,
        AutoFocusModule,
        SidebarModule,
        SpeedDialModule,
    ],
    exports: [
        UniGraphComponent,
        LoaderComponent,
        ButtonModule,
        CheckboxModule,
        PasswordModule,
        FormsModule,
        AccordionModule,
        InputTextModule,
        AppConfigModule,
        SelectButtonModule,
        TranslateModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        ProgressSpinnerModule,
        ChartModule,
        InputNumberModule,
        SkeletonModule,
        TableModule,
        DividerModule,
        ProgressBarModule,
        PanelModule,
        DialogModule,
        CellEditorModalComponent,
        SmartLoaderComponent,
        ModalComponent,
        ToastModule,
        SliderModule,
        InplaceModule,
        ConfirmPopupModule,
        TagModule,
        MultiSelectModule,
        KnobModule,
        OverlayPanelModule,
        AutoFocusModule,
        SidebarModule,
        SpeedDialModule,
        FloatLabelModule,
    ],
})
export class SharedModule {}
