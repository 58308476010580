<div class="relative card w-full overflow-hidden">
    @if (!!settings.info) {
    <div
        class="absolute top-0 right-0 p-3 z-5 cursor-pointer select-none"
        (click)="toggleInfo()"
    >
        @if (showInfo) {
        <i class="pi pi-times"></i>
        } @else {
        <i class="pi pi-info-circle"></i>
        }
    </div>
    }

    <div
        [@toggle]="showInfo ? 'open' : 'close'"
        class="absolute top-0 right-0 flex w-full h-full card z-2 flex flex-column"
    >
        <p class="text-primary font-bold text-xl">Info:</p>
        <p>{{ settings.info }}</p>
    </div>
    <p class="text-2xl font-bold text-center text-primary">{{ settings.title }}</p>
    <div class="flex py-2 flex-column lg:flex-row">
        <div class="flex flex-column align-items-center w-full">
            @if (!!settings.graphTitle) {
            <p>{{ settings.graphTitle }}</p>
            }
            <div class="h-10rem p-3">
                <p-chart
                    type="bar"
                    [data]="{
                        labels: settings.labels,
                        datasets: settings.datasets
                    }"
                    [options]="{
                        maintainAspectRatio: false,
                        aspectRatio: 0.6,
                        plugins: {
                            legend: {
                                display: settings.legend || false
                            }
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                grace: '10%'
                            }
                        }
                    }"
                    height="100%"
                />
            </div>
        </div>
        @if (!!settings.sideMetric) {
        <div
            [ngClass]="[
                'flex flex-column justify-content-center align-items-center',
                'border-black-alpha-20 p-2 w-full border-top-2 lg:border-left-2 lg:border-top-none'
            ]"
        >
            <p class="text-2xl">{{ settings.sideMetric.title }}</p>
            <p class="text-4xl text-primary-600">{{ settings.sideMetric.value }}{{ settings.sideMetric.suffix }}</p>
        </div>
        }
    </div>
    @if (!!settings.detailsUrl) {
    <div class="flex justify-content-center lg:justify-content-end">
        <p-button
            [routerLink]="settings.detailsUrl"
            [icon]="Icons.CHEVRON_RIGHT"
            label="Einzelheiten"
            iconPos="right"
        />
    </div>
    }
</div>
