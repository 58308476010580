<p-dialog
    [header]="header"
    [(visible)]="visible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    appendTo="body"
    (onHide)="closeModal()"
    [dismissableMask]="true"
    styleClass="text-center w-11 sm:w-8 md:w-6"
>
    <p-inputNumber [(ngModel)]="value"> </p-inputNumber>

    <ng-template pTemplate="footer">
        <p-button
            icon="pi pi-check"
            (click)="saveChanges()"
            label="Save"
            styleClass="p-button-text"
        ></p-button>
    </ng-template>
</p-dialog>
