export let allowedRoutes = [
    {
        route: 'articles/supplier',
        requiredPermission: 'pages_can_view_articles_supplier',
    },
    {
        route: 'articles/internal',
        requiredPermission: 'pages_can_view_articles_internal',
    },
];
