import { Component, OnInit } from '@angular/core';

import * as lottie from 'lottie-web';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
    templateUrl: './comingsoon.component.html',
})
export class ComingSoonComponent implements OnInit {
    subscriptions: Subscription[] = [];

    constructor(private layoutService: LayoutService) {
        this.subscriptions.push(
            this.layoutService.configUpdate$.subscribe(() => {
                this.startLottie();
            }),
        );
    }

    ngOnInit() {
        this.startLottie();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    startLottie() {
        let style = getComputedStyle(document.body);

        // Define a mapping of old colors to new colors
        let colorMapping: any = {
            '#3A4258': style.getPropertyValue('--text-color'),
            '#F1EFEB': style.getPropertyValue('--surface-a'),
            '#D6455C': style.getPropertyValue('--primary-color'),
            '#282D3D': style.getPropertyValue('--primary-color'),
            '#B6B8B8': style.getPropertyValue('--primary-100'),
            '#F0666F': style.getPropertyValue('--primary-color'),
            '#D7D5D5': style.getPropertyValue('--primary-100'),
            '#FFFFFF': style.getPropertyValue('--surface-a'),
            '#E9E9E9': style.getPropertyValue('--primary-100'),
            '#151F2C': style.getPropertyValue('--text-color'),
            '#EBF3FA': style.getPropertyValue('--surface-a'),
        };

        // Load the original Lottie JSON
        fetch('../../../../assets/images/coming-soon-animation.json')
            .then((response) => response.json())
            .then((data) => {
                let occurrences_all_colors_hex: any = {
                    '#3A4258': [
                        ['layers', 0, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 1, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 3, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 3, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 3, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 3, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 6, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 7, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 8, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 9, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 10, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 11, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 12, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 24, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 25, 'it', 1, 'c', 'k'],
                        ['layers', 6, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 19, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 19, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 22, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 22, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 22, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 22, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 23, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 23, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 23, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 23, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 24, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 24, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 24, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 24, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 29, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 33, 'shapes', 0, 'it', 3, 'c', 'k'],
                        ['layers', 34, 'shapes', 0, 'it', 3, 'c', 'k'],
                    ],
                    '#F1EFEB': [
                        ['layers', 2, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 2, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 2, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 3, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 5, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 7, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 7, 'shapes', 2, 'it', 2, 'c', 'k'],
                        ['layers', 7, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 17, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 21, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 29, 'shapes', 0, 'it', 2, 'c', 'k'],
                    ],
                    '#D6455C': [
                        ['layers', 2, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 28, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 29, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 31, 'it', 1, 'c', 'k'],
                    ],
                    '#282D3D': [
                        ['layers', 4, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 6, 'shapes', 0, 'it', 1, 'c', 'k'],
                    ],
                    '#D8D9D9': [
                        ['layers', 4, 'shapes', 13, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 14, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 15, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 16, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 17, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 18, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 19, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 20, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 21, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 22, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 23, 'it', 1, 'c', 'k'],
                        ['layers', 10, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 10, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 2, 'it', 3, 'c', 'k'],
                        ['layers', 12, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 4, 'it', 2, 'c', 'k'],
                    ],
                    '#B6B8B8': [
                        ['layers', 4, 'shapes', 26, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 27, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 30, 'it', 1, 'c', 'k'],
                    ],
                    '#F0666F': [
                        ['layers', 7, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 7, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 7, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 7, 'shapes', 4, 'it', 3, 'c', 'k'],
                        ['layers', 8, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 9, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 9, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 11, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 11, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 14, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 15, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 15, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 15, 'shapes', 2, 'it', 3, 'c', 'k'],
                        ['layers', 16, 'shapes', 0, 'it', 29, 'c', 'k'],
                        ['layers', 17, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 18, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 18, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 18, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 18, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 18, 'shapes', 4, 'it', 1, 'c', 'k'],
                        ['layers', 18, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 18, 'shapes', 6, 'it', 1, 'c', 'k'],
                        ['layers', 19, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 19, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 20, 'shapes', 0, 'it', 60, 'c', 'k'],
                        ['layers', 21, 'shapes', 0, 'it', 59, 'c', 'k'],
                        ['layers', 22, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 22, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 23, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 23, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 24, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 24, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 25, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 25, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 26, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 26, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 27, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 27, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 28, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 30, 'shapes', 0, 'it', 3, 'c', 'k'],
                        ['layers', 31, 'shapes', 0, 'it', 3, 'c', 'k'],
                    ],
                    '#D7D5D5': [
                        ['layers', 4, 'shapes', 13, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 14, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 15, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 16, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 17, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 18, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 19, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 20, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 21, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 22, 'it', 1, 'c', 'k'],
                        ['layers', 4, 'shapes', 23, 'it', 1, 'c', 'k'],
                        ['layers', 10, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 10, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 2, 'it', 3, 'c', 'k'],
                        ['layers', 12, 'shapes', 3, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 4, 'it', 2, 'c', 'k'],
                    ],
                    '#FFFFFF': [
                        ['layers', 11, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 11, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 11, 'shapes', 2, 'it', 3, 'c', 'k'],
                    ],
                    '#E9E9E9': [
                        ['layers', 11, 'shapes', 4, 'it', 2, 'c', 'k'],
                        ['layers', 14, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 14, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 14, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 19, 'shapes', 3, 'it', 2, 'c', 'k'],
                        ['layers', 25, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 26, 'shapes', 2, 'it', 1, 'c', 'k'],
                        ['layers', 27, 'shapes', 2, 'it', 1, 'c', 'k'],
                    ],
                    '#151F2C': [
                        ['layers', 11, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 11, 'shapes', 6, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 5, 'it', 1, 'c', 'k'],
                        ['layers', 12, 'shapes', 6, 'it', 1, 'c', 'k'],
                    ],
                    '#EBF3FA': [
                        ['layers', 13, 'shapes', 0, 'it', 1, 'c', 'k'],
                        ['layers', 13, 'shapes', 1, 'it', 1, 'c', 'k'],
                        ['layers', 13, 'shapes', 2, 'it', 1, 'c', 'k'],
                    ],
                };

                // Replace colors in the JSON
                for (let oldColor in colorMapping) {
                    let newColor = colorMapping[oldColor];
                    let occurrences = occurrences_all_colors_hex[oldColor]; // from our previous extraction
                    for (let path of occurrences) {
                        let node = data;
                        for (let i = 0; i < path.length - 1; i++) {
                            node = node[path[i]];
                        }
                        node[path[path.length - 1]] = this.hex_to_rgb(newColor);
                    }
                }

                // Render the updated Lottie animation
                lottie.default.destroy(); // Clear the previous animation
                lottie.default.loadAnimation({
                    container: document.getElementById('lottie-animation')!,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: data,
                });
            });
    }

    hex_to_rgb(hexColor: string) {
        return hexColor
            ?.slice(1)
            ?.match(/.{2}/g)
            ?.map((hex) => parseInt(hex, 16) / 255.0);
    }
}
