import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'ÜBERSICHT',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'KPI-Dashboard',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/kpi-dashboard'],
                    },
                    {
                        label: 'Alert Monitor',
                        icon: 'pi pi-fw pi-exclamation-triangle',
                        routerLink: ['/alert-monitor'],
                    }
                ],
            },
            {
                label: 'BESCHAFFUNG',
                icon: 'pi pi-fw pi-shopping-cart',
                items: [
                    {
                        label: 'Beschaffungsmatrix',
                        icon: 'pi pi-fw pi-th-large',
                        routerLink: ['/procurement-matrix'],
                    },
                    {
                        label: 'Analyse + Dispo',
                        icon: 'pi pi-fw pi-chart-line',
                        routerLink: ['/analyse-dispo'],
                    },
                    {
                        label: 'Artikelplanung Intern',
                        icon: 'pi pi-fw pi-building',
                        routerLink: ['/articles/internal'],
                    },
                    {
                        label: 'Artikelplanung Lieferant',
                        icon: 'pi pi-fw pi-truck',
                        routerLink: ['/articles/supplier'],
                    },
                ],
            },
            {
                label: 'LOGISTIK',
                icon: 'pi pi-fw pi-box',
                items: [
                    {
                        label: 'Sortimente Übersicht',
                        icon: 'pi pi-fw pi-eye',
                        routerLink: ['/assortment/overview'],
                    },
                    {
                        label: 'Sortimente Lagerort',
                        icon: 'pi pi-fw pi-chart-pie',
                        routerLink: ['/assortment/station'],
                    },
                    {
                        label: 'Dispo-Parameter',
                        icon: 'pi pi-fw pi-chart-line',
                        routerLink: ['/dispo-parameter'],
                    },
                    {
                        label: 'Station',
                        icon: 'pi pi-fw pi-inbox',
                        routerLink: ['/station'],
                    },
                    {
                        label: 'Schrank',
                        icon: 'pi pi-fw pi-calculator',
                        routerLink: ['/closet'],
                    },
                ],
            },
            // {
            //     label: 'TOPA',
            //     icon: 'pi pi-fw pi-dollar',
            //     items: [
            //         {
            //             label: 'Mehrwertanalyse USZ',
            //             icon: 'pi pi-fw pi-dollar',
            //             routerLink: ['/added-value-analysis'],
            //         },
            //         {
            //             label: 'KAZ + USZ',
            //             icon: 'pi pi-fw pi-arrow-right-arrow-left',
            //             routerLink: ['/kaz-usz'],
            //         },
            //     ]
            // },
            // {
            //     label: 'AEMP',
            //     icon: 'pi pi-fw pi-sync',
            //     items: [
            //         {
            //             label: 'Performance',
            //             icon: 'pi pi-fw pi-stopwatch',
            //             routerLink: ['/performance'],
            //         },
            //         {
            //             label: 'Produktionsplanung',
            //             icon: 'pi pi-fw pi-directions',
            //             routerLink: ['/production-planning'],
            //         },
            //     ]
            // },
            // {
            //     label: 'OP-VERSORGUNG',
            //     icon: 'pi pi-fw pi-sitemap',
            //     items: [
            //         {
            //             label: 'Richtschemen',
            //             icon: 'pi pi-fw pi-file-edit',
            //             routerLink: ['/op-schemes'],
            //         },
            //         {
            //             label: 'Stücklistenoptimierung',
            //             icon: 'pi pi-fw pi-bolt',
            //             routerLink: ['/schemes-optimization'],
            //         },
            //         {
            //             label: 'Set-Vorschläge',
            //             icon: 'pi pi-fw pi-box',
            //             routerLink: ['/set-proposals'],
            //         },
            //     ]
            // },
            {
                label: 'WEITERES',
                icon: 'pi pi-fw pi-wrench',
                items: [
                    // {
                    //     label: 'Übersicht Anpassungen und Notizen',
                    //     icon: 'pi pi-fw pi-file-edit',
                    //     routerLink: ['/adjustments-notes'],
                    // },
                    {
                        label: 'Download Center',
                        icon: 'pi pi-fw pi-download',
                        routerLink: ['/download-center'],
                    },
                ],
            },
        ];
    }
}
