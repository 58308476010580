<div class="layout-topbar">
    <div class="topbar-start">
        <button
            #menubutton
            type="button"
            class="topbar-menubutton p-link p-trigger transition-duration-300"
            (click)="onMenuButtonClick()"
        >
            <i class="pi pi-bars"></i>
        </button>
    </div>
    <div class="layout-topbar-menu-section">
        <app-sidebar></app-sidebar>
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">
            <!-- <p-button
                icon="pi pi-info-circle"
                label="DEMO"
                styleClass="p-button-text p-button-secondary"
                iconPos="right"
                pTooltip="Hinweis: Bitte beachten Sie, dass es sich hierbei um eine Demoversion des AI-Cockpits handelt und einige Funktionen eingeschränkt sind."
            ></p-button>
            <p-button
                icon="pi pi-exclamation-triangle"
                label="Testdaten"
                styleClass="p-button-text p-button-info"
                iconPos="right"
                pTooltip="Hinweis: Bitte beachten Sie, dass die angezeigten Daten zu Demonstrationszwecken erstellt wurden und nicht die tatsächlichen Ergebnisse Ihrer Organisation widerspiegeln."
            ></p-button> -->
            <li class="topbar-item">
                <a
                    pStyleClass="@next"
                    enterFromClass="ng-hidden"
                    enterActiveClass="px-scalein"
                    leaveToClass="ng-hidden"
                    leaveActiveClass="px-fadeout"
                    [hideOnOutsideClick]="true"
                    pRipple
                    class="cursor-pointer"
                >
                    <p-avatar
                        [label]="userInitials"
                        styleClass="bg-primary"
                    ></p-avatar>
                </a>
                <ul [class]="'topbar-menu active-topbar-menu p-4 w-15rem z-5 ng-hidden'">
                    <li
                        role="menuitem"
                        class="m-0 mb-3"
                    >
                        <a
                            class="flex align-items-center hover:text-primary-500 transition-duration-200 cursor-pointer"
                            pStyleClass="@grandparent"
                            enterFromClass="ng-hidden"
                            enterActiveClass="px-scalein"
                            leaveToClass="ng-hidden"
                            leaveActiveClass="px-fadeout"
                        >
                            <i class="pi pi-fw pi-lock mr-2"></i>
                            <span>Privacy</span>
                        </a>
                    </li>
                    <li
                        role="menuitem"
                        class="m-0 mb-3"
                    >
                        <a
                            class="flex align-items-center hover:text-primary-500 transition-duration-200 cursor-pointer"
                            pStyleClass="@grandparent"
                            enterFromClass="ng-hidden"
                            enterActiveClass="px-scalein"
                            leaveToClass="ng-hidden"
                            leaveActiveClass="px-fadeout"
                        >
                            <i class="pi pi-fw pi-cog mr-2"></i>
                            <span>Settings</span>
                        </a>
                    </li>
                    <li
                        role="menuitem"
                        class="m-0"
                    >
                        <a
                            (click)="logout()"
                            class="flex align-items-center hover:text-primary-500 transition-duration-200 cursor-pointer"
                            pStyleClass="@grandparent"
                            enterFromClass="ng-hidden"
                            enterActiveClass="px-scalein"
                            leaveToClass="ng-hidden"
                            leaveActiveClass="px-fadeout"
                        >
                            <i class="pi pi-fw pi-sign-out mr-2"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="">
                <button
                    pButton
                    type="button"
                    icon="pi pi-cog"
                    class="p-button-text p-button-secondary flex-shrink-0"
                    (click)="onConfigButtonClick()"
                ></button>
            </li>
        </ul>
    </div>
</div>
