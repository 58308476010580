import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { AccessdeniedComponent } from './components/accessdenied/accessdenied.component';
import { ErrorComponent } from './components/error/error.component';
import { FaqComponent } from './components/faq/faq.component';
import { ForgotPasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { HelpComponent } from './components/help/help.component';
import { LockScreenComponent } from './components/lockscreen/lockscreen.component';
import { LoginComponent } from './components/login/login.component';
import { NewPasswordComponent } from './components/newpassword/newpassword.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { RegisterComponent } from './components/register/register.component';
import { VerificationComponent } from './components/verification/verification.component';
import { AppConfigModule } from '../layout/config/app.config.module';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ComingSoonComponent } from './components/comingsoon/comingsoon.component';
import { SocketService } from './services/socket.service';

@NgModule({
    providers: [TranslateService, ConfirmationService, MessageService, DatePipe],
    declarations: [
        AccessdeniedComponent,
        ErrorComponent,
        FaqComponent,
        ForgotPasswordComponent,
        HelpComponent,
        LockScreenComponent,
        LoginComponent,
        NewPasswordComponent,
        NotfoundComponent,
        RegisterComponent,
        VerificationComponent,
        ComingSoonComponent,
    ],
    imports: [CommonModule, CoreRoutingModule, SharedModule, AppConfigModule],
})
export class CoreModule {
    constructor(
        private config: PrimeNGConfig,
        private translate: TranslateService,
        private socketService: SocketService,
        private messageService: MessageService,
    ) {
        this.translate.setDefaultLang('de');

        this.translate.onLangChange.subscribe((event) => {
            localStorage.setItem('lang', event.lang);
            this.config.setTranslation(this.translate.instant('primeng'));
        });

        this.translate.use(localStorage.getItem('lang') || 'de');

        this.socketService.message.subscribe((message: any) => {
            messageService.add({ severity: 'success', summary: translate.instant('download-center.task-complete') });
        });
    }
}
