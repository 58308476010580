import { Injectable } from '@angular/core';
// import { Filters as DashboardFilters } from 'src/app/pages/dashboard-legacy/models/filters';

@Injectable({
    providedIn: 'root',
})
export class CachingService {
    constructor() {}

    getDashboardData(): any | null {
        let data = localStorage.getItem('dashboardData');
        if (!data) return null;
        return JSON.parse(localStorage.getItem('dashboardData')!);
    }

    setDashboardData(data: any): void {
        localStorage.setItem('dashboardData', JSON.stringify(data));
    }

    deleteDashboardData(): void {
        localStorage.removeItem('dashboardData');
    }

    deleteAll(): void {
        localStorage.clear();
    }
}
