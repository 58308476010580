import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import guards
import { LoginGuard } from './helpers/login.guard';
import { AuthGuard } from './helpers/auth.guard';

// Import components
import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { LockScreenComponent } from './components/lockscreen/lockscreen.component';

const routes: Routes = [
    //{path: '', redirectTo: 'comingsoon',pathMatch:'full'},
    {
        path: '',
        loadChildren: () => import('../layout/app.layout.module').then((m) => m.AppLayoutModule),
        canActivate: [AuthGuard],
    },
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: '404', component: NotfoundComponent },
    { path: '**', component: NotfoundComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
