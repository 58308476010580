<nav class="layout-breadcrumb">
    <ol>
        <ng-template
            ngFor
            let-item
            let-last="last"
            [ngForOf]="breadcrumbs$ | async"
        >
            <li>{{ item.label }}</li>
            <li
                *ngIf="!last"
                class="layout-breadcrumb-chevron"
            >
                /
            </li>
        </ng-template>
    </ol>
</nav>
