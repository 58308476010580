import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-cell-editor-modal',
    templateUrl: './cell-editor-modal.component.html',
    styleUrls: ['./cell-editor-modal.component.scss'],
})
export class CellEditorModalComponent implements OnInit, OnChanges {
    initialValue: any = undefined;

    @Input() value: any = undefined;

    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    @Input() header: string = 'Editor';

    @Input() visible: boolean = false;

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

    @Output() onDone: EventEmitter<any> = new EventEmitter();

    constructor(private confirmationService: ConfirmationService) {}

    ngOnInit(): void {
        if (this.value) {
            this.initialValue = JSON.parse(JSON.stringify(this.value));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {}

    closeModal(alert: boolean = false) {
        if (alert && this.initialValue != this.value) {
            this.openAlert();
            return;
        }
        this.visible = false;
        this.visibleChange.emit(false);
    }

    saveChanges() {
        this.closeModal();
    }

    openAlert() {
        this.confirmationService.confirm({
            message: 'Discard changes?',
            accept: () => {
                this.closeModal();
            },
        });
    }
}
