<div class="flex justify-content-center">
    <h1 class="text-primary">Coming soon!</h1>
</div>

<div class="flex justify-content-center mb-8">
    <div
        id="lottie-animation"
        style="height: 600px"
    ></div>
</div>
