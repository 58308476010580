import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
    @Input()
    open: boolean = false;
    @Output() openChange: EventEmitter<boolean> = new EventEmitter();

    @Input()
    formChanged: boolean = false;
    @Output() formChangedChange: EventEmitter<boolean> = new EventEmitter();

    @Input()
    warningFunction: Function | undefined = undefined;

    visible: boolean = false;

    displayed: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['open']) {
            if (this.open) {
                this.displayed = true;
                setTimeout(() => {
                    this.visible = true;
                }, 1);
            } else {
                this.visible = false;
                setTimeout(() => {
                    this.displayed = false;
                }, 200);
            }
        }
    }

    closeModal(warning: boolean = false) {
        if (!warning || !this.formChanged) {
            this.open = false;
            this.openChange.emit(false);
            return;
        }

        if (this.warningFunction) this.warningFunction();
    }
}
