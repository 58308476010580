<div class="surface-0">
    <div class="flex align-items-center justify-content-between flex-column h-screen">
        <div
            class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-4"
        >
            <a
                [routerLink]="['/']"
                class="mb-6"
            >
                <svg
                    height="56"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 0H6.00019V3.82345L17 1.66667V6.66667L6.00019 8.82345V10.4901L17 8.33333V13.3333L6.00019 15.4901V20H0V0Z"
                        fill="url(#paint0_linear)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear"
                            x1="3.33335"
                            y1="3.08442e-08"
                            x2="8.49995"
                            y2="20"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="var(--primary-400)" />
                            <stop
                                offset="1"
                                stop-color="var(--primary-700)"
                            />
                        </linearGradient>
                    </defs>
                </svg>
            </a>
            <div class="mb-4">
                <div class="text-900 text-xl font-bold mb-2">Forgot Password</div>
                <span class="text-600 font-medium">Enter your email to reset your password</span>
            </div>
            <div class="flex flex-column">
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-envelope"></i>
                    <input
                        id="email"
                        type="text"
                        pInputText
                        class="w-full md:w-25rem text-color-secondary surface-50 border-200"
                        placeholder="Email"
                    />
                </span>
                <div class="flex flex-wrap gap-2 justify-content-between">
                    <button
                        pButton
                        pRipple
                        label="Cancel"
                        class="flex-auto p-button-outlined"
                        [routerLink]="['/']"
                    ></button>
                    <button
                        pButton
                        pRipple
                        label="Submit"
                        class="flex-auto"
                        [routerLink]="['/']"
                    ></button>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap align-items-center pb-8 px-4">
            <h4
                class="m-0 mr-5"
                style="line-height: 22px"
            >
                freya
            </h4>
            <h6
                class="m-0 font-medium text-300"
                style="line-height: 17px"
            >
                Copyright Ⓒ PrimeTek Informatics
            </h6>
        </div>
    </div>
</div>
<app-config [minimal]="true"></app-config>
