export let menuItems = [
    {
        label: 'Dashboard',
        routerLink: 'dashboard',
        disabled: false,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_supplier', //change this to be specific for dashboard page
    },
    {
        label: 'Sortimente Übersicht',
        routerLink: 'assortments/overview',
        disabled: false,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_supplier',
    },
    {
        label: 'Sortimente Station',
        routerLink: 'assortments/station',
        disabled: false,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_supplier',
    },
    {
        label: 'Gesamtbild',
        routerLink: 'overview',
        disabled: true,
        comingSoon: true,
        requiredPermission: 'pages_can_view_overview',
    },
    {
        label: 'Beschaffungsmatrix',
        routerLink: 'disposition/matrix',
        disabled: false,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_supplier',
    },
    // {
    //     label: 'Beschaffungsmatrix (Legacy)',
    //     routerLink: 'disposition/matrix',
    //     disabled: true,
    //     comingSoon: false,
    //     requiredPermission: 'pages_can_view_articles_supplier',
    // },
    {
        label: 'Artikelplanung intern',
        routerLink: 'articles/internal',
        disabled: false,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_internal',
    },
    {
        label: 'Artikelplanung Lieferant',
        routerLink: 'articles/supplier',
        disabled: true,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_supplier',
    },
    {
        label: 'Artikelanalyse',
        routerLink: 'articles/analysis',
        disabled: true,
        comingSoon: true,
        requiredPermission: 'pages_can_view_analysis',
    },
    {
        label: 'Übersicht Anpassungen und Notizen',
        routerLink: 'settings',
        disabled: true,
        comingSoon: true,
        requiredPermission: 'pages_can_view_settings',
    },
    {
        label: 'Download center',
        routerLink: 'download',
        disabled: false,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_supplier',
    },
    {
        label: 'Prototyp KPI',
        routerLink: 'experimental',
        disabled: false,
        comingSoon: false,
        requiredPermission: 'pages_can_view_articles_supplier',
    },
];
