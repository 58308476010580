<div
    class="layout-sidebar"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    style="z-index: 999"
>
    <div class="sidebar-header">
        <a
            [routerLink]="['/']"
            class="app-logo cursor-pointer"
        >
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1000 328"
                style="enable-background: new 0 0 1000 328; width: 100%; height: auto"
                xml:space="preserve"
                class="app-logo-normal"
            >
                <style type="text/css">
                    .customer-logo-1-st0 {
                        fill: var(--gray-400);
                    }
                    .st1 {
                        fill: var(--primary-400);
                    }
                </style>
                <polygon
                    class="customer-logo-1-st0"
                    points="458.3,236.7 458.4,138.5 477.2,138.5 477.4,220.2 515.4,220.2 515.4,236.7 "
                />
                <polygon
                    class="customer-logo-1-st0"
                    points="374,236.7 374.2,138.5 427.1,138.5 427.1,155.1 392.6,155.1 392.4,178.9 426.6,178.9 426.6,194.5 
                    392.8,194.5 392.6,220.2 427.1,220.2 427.1,236.7 "
                />
                <polygon
                    class="customer-logo-1-st0"
                    points="181.4,236.7 181.3,137.9 201.2,137.9 244.5,209.9 244.7,138.5 262.4,138.5 262.4,237.2 241.7,237.2 
                    199.1,165.7 199.3,236.7 "
                />
                <polygon
                    class="customer-logo-1-st0"
                    points="131.7,236.7 131.8,138.5 150.6,138.5 150.7,236.7 "
                />
                <path
                    class="st1"
                    d="M52.6,206.7h-7.4v-82.9c0-16-18.7-26.8-26-28.9L12.8,105c3.7,1.4,17.2,6.2,17.2,18.4v72.2
                    c-14-9.9-24-22-24-38.8V90h93.3v66.8c0,16.8-10,28.9-23.9,38.8v-72.3c0-12.3,13.4-17,17.1-18.4L86,94.8c-7.3,2.2-26,13-26,28.9v82.9
                    L52.6,206.7z"
                />
                <path
                    class="customer-logo-1-st0"
                    d="M334.5,138.6c4.6,1.3,9,3.2,13.1,5.6l0.4,0.3l-0.3,0.5l-7,13.7l-0.3,0.5l-0.5-0.3c-2.8-1.9-5.9-3.5-9.1-4.7
                    c-2.5-1-5.1-1.6-7.8-1.8c-4-0.2-7.8,1.1-10.9,3.7c-2.7,2.5-4.3,6-4.2,9.7c0.1,8,7.4,11.1,15.7,14.6c11.2,4.7,24.1,10.3,24.1,29.2
                    v0.2l0,0c0,7.7-3.3,15-9.1,20.1c-6.6,5.5-14.9,8.5-23.5,8.2h-0.3c-5.8-0.1-11.5-1.2-16.9-3c-5-1.7-9.8-3.9-14.3-6.5l-0.5-0.3
                    l0.3-0.5l8-13.6l0.3-0.4l0.5,0.3c0.7,0.4,1.6,0.9,2.4,1.3c5.7,3.8,12.2,6.3,18.9,7.2c4,0.1,7.9-1.1,11.2-3.5
                    c2.7-2.1,4.2-5.4,4.1-8.9c0-8-7.7-11.4-16.4-15.3c-11.4-5.1-24.3-10.8-24.3-28.4c-0.1-8.1,3.3-15.8,9.3-21.2
                    c6.6-5.6,15-8.6,23.6-8.5C325.5,136.8,330,137.4,334.5,138.6"
                />
                <path
                    class="st1"
                    d="M537.5,230.5c7.9,5.2,17.2,7.9,26.6,7.7c21.3,0,31.4-13.5,31.4-27.9c0-30.4-43.6-25.6-43.6-46.9
                    c-0.1-9.3,7.5-17,16.8-17c0.4,0,0.7,0,1.1,0c7.5,0.1,14.7,2.6,20.6,7.2l4.6-8.9c-7.8-4.9-16.7-7.5-25.8-7.6
                    c-17.2,0-29.5,10.5-29.5,25.5c0,34,42.8,23.9,42.8,48.5c0.2,9.6-7.4,17.5-17,17.7c-0.7,0-1.5,0-2.2-0.1c-8.5-0.8-16.6-3.8-23.5-8.7
                    l-5.3,8.7L537.5,230.5z"
                />
                <path
                    class="st1"
                    d="M994,236.2v-10.1h-40.8l0.3-87h-12v97.1L994,236.2L994,236.2z M888.3,193.9h-31.9l16.3-43.8h0.3L888.3,193.9z
                    M826.1,236.3h12.5l13.6-32.4h40.1l12.5,32.4h13.5L879,139.2h-11.5L826.1,236.3z M750.3,149.2H781l0.1,87h11.7l0.2-87h30.7v-10.1
                    h-73.5L750.3,149.2L750.3,149.2z M713,236.2h12.1l0.1-97.1h-12L713,236.2z M635.8,236.2l0.1-40.1h13.9c16,0,34.5-7.4,34.5-29
                    c0-18.5-12.4-27.9-30.8-27.9h-29.3v97.1H635.8z M636,147.5h14.6c15.7,0,21.6,9.1,21.6,19.5c0.3,10.7-8.1,19.5-18.8,19.8
                    c-0.8,0-1.5,0-2.3-0.1h-15.4L636,147.5z"
                />
            </svg>

            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 150 150"
                style="enable-background: new 0 0 150 150; height: 3.5rem"
                xml:space="preserve"
                class="app-logo-single"
            >
                <style type="text/css">
                    .customer-logo-2-st0 {
                        fill: var(--primary-400);
                    }
                </style>
                <path
                    class="customer-logo-2-st0"
                    d="M74.7,146.5h-9V46.6c0-19.3-22.5-32.3-31.3-34.9l-7.7,12.3c4.5,1.6,20.7,7.4,20.7,22.2v87
                    c-16.9-11.9-28.9-26.5-28.9-46.8V6h112.5v80.5c0,20.3-12.1,34.9-28.8,46.8V46.2c0-14.8,16.1-20.5,20.6-22.2l-7.7-12.3
                    c-8.8,2.7-31.3,15.6-31.3,34.9v99.9L74.7,146.5z"
                />
            </svg>
        </a>
        <button
            class="layout-sidebar-anchor p-link"
            type="button"
            (click)="anchor()"
        ></button>
    </div>

    <div
        #menuContainer
        class="layout-menu-container"
    >
        <app-menu></app-menu>
    </div>

    <div class="sidebar-header px-3 align-self-center">
        <a
            href="https://www.prognosix.ch/"
            target="_blank"
            class="app-logo cursor-pointer"
        >
            <!-- <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 331.87 127.35">
                <defs>
                <style>.prognosix-logo-1-st0{fill:var(--gray-300)}.cls-2{fill:var(--gray-300)}.cls-3{fill:url(#Unbenannter_Verlauf_3)}</style>
                <linearGradient id="Unbenannter_Verlauf_3" x1="393.51" y1="226.57" x2="469.07" y2="226.57" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#0d955e"/>
                    <stop offset="0.31" stop-color="#3ca449"/>
                    <stop offset="0.78" stop-color="#7eba2b"/>
                    <stop offset="1" stop-color="#98c21f"/>
                </linearGradient></defs>
                <title>Prognosix_Logo ohne</title>
                <path class="cls-1" d="M467.82,325.81" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M166.82,289.2q0,7.32-3.44,10.87t-11.18,3.54h-6.74v12.54H137.2V275.6h15.23q6.88,0,10.63,3.21T166.82,289.2Zm-8.53.06q0-3.43-1.89-5t-6.81-1.52h-4.13V296.4h3.37a21.12,21.12,0,0,0,6.29-.63,4.16,4.16,0,0,0,2.46-2.12A10.51,10.51,0,0,0,158.3,289.25Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M196.29,316.15,186,300.8h-4.19v15.35H173.5V275.6h14.74q7.82,0,11.44,2.81t3.62,9.23a14,14,0,0,1-2.18,8.26,9.76,9.76,0,0,1-6.2,3.93l10.84,16.32ZM194.92,288q0-3.11-1.57-4.16t-5.17-1.05h-6.42v10.81h6.06a13.45,13.45,0,0,0,4.28-.53,3.88,3.88,0,0,0,2.15-1.64A6.93,6.93,0,0,0,194.92,288Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M244.17,295q0,10.58-4.47,15.85t-13,5.27q-9,0-13.23-5.55T209.24,295q0-10.08,4.23-15.6t13.23-5.52q9.05,0,13.26,5.52T244.17,295Zm-8.53,0q0-7.21-2-10.5t-6.9-3.29q-4.86,0-6.9,3.29t-2,10.5q0,7.21,2.07,10.5t6.87,3.3q4.86,0,6.9-3.3T235.64,295Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M277.89,286.15a10.12,10.12,0,0,0-3.78-3.72,11.69,11.69,0,0,0-5.6-1.2q-5.54,0-8,3.56T258,295.38q0,7,2.45,10.21t7.87,3.24a16.9,16.9,0,0,0,7.56-1.7v-6.3H268v-7.29h16.2v17.93a22.73,22.73,0,0,1-7,3.4,29,29,0,0,1-8.6,1.29q-9.49,0-14.3-5t-4.8-15.82q0-21.47,18.9-21.47a23.24,23.24,0,0,1,9.61,1.85,13.49,13.49,0,0,1,6.24,5.42Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M315.39,316.15l-14.77-29v29h-8.09V275.6h10.72L317,302.94V275.6h8.12v40.55Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M366.92,295q0,10.58-4.47,15.85t-13,5.27q-9,0-13.23-5.55T332,295q0-10.08,4.23-15.6t13.23-5.52q9.05,0,13.26,5.52T366.92,295Zm-8.53,0q0-7.21-2-10.5t-6.9-3.29q-4.86,0-6.9,3.29t-2,10.5q0,7.21,2.06,10.5t6.87,3.3q4.86,0,6.9-3.3T358.39,295Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M403,303.55q0,6.09-4,9.35t-11.79,3.25a22.67,22.67,0,0,1-9.36-1.79,14.62,14.62,0,0,1-6.46-5.77l6.36-4.86a14.39,14.39,0,0,0,2.67,3,9.42,9.42,0,0,0,3,1.55,13,13,0,0,0,4,.56,10.08,10.08,0,0,0,5.26-1.16,3.86,3.86,0,0,0,1.89-3.53,3.74,3.74,0,0,0-.66-2.26,5.32,5.32,0,0,0-2-1.57,26.26,26.26,0,0,0-4.57-1.51q-3.16-.85-6-1.71a18.13,18.13,0,0,1-4.83-2.24,10.14,10.14,0,0,1-3.21-3.46,10.58,10.58,0,0,1-1.17-5.24,11.07,11.07,0,0,1,3.9-9q3.9-3.24,11.16-3.24,10.52,0,14.88,7.21l-6.3,4.92a10.51,10.51,0,0,0-3.75-3.69,10.35,10.35,0,0,0-5-1.11,9.27,9.27,0,0,0-4.67,1,3.51,3.51,0,0,0-1.8,3.27,3.27,3.27,0,0,0,1.88,3,27.21,27.21,0,0,0,6.53,2.1q2.58.67,5.1,1.45a16.12,16.12,0,0,1,4.5,2.15,10.23,10.23,0,0,1,3.18,3.6A12,12,0,0,1,403,303.55Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M428,316.15H408.43v-6.36h5.65V282h-5.65V275.6H428V282h-5.62v27.83H428Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-2" d="M431.84,316.15l13.56-20.33L432.19,275.6h9.4L450,289.25l8.7-13.65h9.11l-13.3,20,13.24,20.51h-9.29l-8.7-14-8.82,14Z" transform="translate(-137.2 -188.79)"/>
                <path class="cls-3" d="M468.06,188.82a1.25,1.25,0,0,0-1.4.75,134.16,134.16,0,0,1-21.92,35.79,71.7,71.7,0,0,0-26,12.12q-8.05-.81-13.8,2.23c-12,6.35-11.48,22.74-11.46,23.44,0,0,0,.05,0,.08s0,.06,0,.09a1.24,1.24,0,0,0,.13.4l0,0a1.24,1.24,0,0,0,.28.32l0,0h0l0,0a1.25,1.25,0,0,0,.29.15l.09,0,.26,0H395l.16,0h.05c.29-.1,16.85-5.8,35.34-21.32,12.14,4.46,25.45,13,36.57,21.15a1.25,1.25,0,0,0,2-1V190A1.25,1.25,0,0,0,468.06,188.82Zm-1.5,36.27a46.35,46.35,0,0,0-18-.46,136.21,136.21,0,0,0,18-28.65Zm-60.42,16.82a19.56,19.56,0,0,1,9.28-2.09h.23a122.84,122.84,0,0,0-19.42,19.4C396.8,254.26,398.85,245.79,406.15,241.91Zm-7.46,18.25a116.85,116.85,0,0,1,20.73-20.07,47.64,47.64,0,0,1,8.33,1.91A128.68,128.68,0,0,1,398.69,260.17ZM430,240.09a56.26,56.26,0,0,0-7.65-2.08,69.79,69.79,0,0,1,19.39-9.31A135.55,135.55,0,0,1,430,240.09Zm2.67,1a137.7,137.7,0,0,0,13.37-13.44c10.51-2.2,18.09-.6,20.49.05v33Q447.24,246.69,432.71,241.07Z" transform="translate(-137.2 -188.79)"/>
            </svg> -->

            <div class="app-logo-normal">
                <div class="flex">
                    <svg
                        id="Ebene_1"
                        data-name="Ebene 1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 493.33 493.29"
                        class="mr-2"
                        style="height: 1.8rem"
                    >
                        <!-- <defs><style>.prognosix-logo-1-st0{fill:url(#Unbenannter_Verlauf_3);}</style> -->
                        <!-- <defs><style>.prognosix-logo-1-st0{fill:var(--primary-400)}</style> -->
                        <defs>
                            <style>
                                .prognosix-logo-1-st0 {
                                    fill: var(--text-color-secondary);
                                }
                            </style>
                            <linearGradient
                                id="Unbenannter_Verlauf_3"
                                x1="50.97"
                                y1="304.57"
                                x2="544.3"
                                y2="304.57"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop
                                    offset="0"
                                    stop-color="#0d955e"
                                />
                                <stop
                                    offset="0.31"
                                    stop-color="#3ca449"
                                />
                                <stop
                                    offset="0.78"
                                    stop-color="#7eba2c"
                                />
                                <stop
                                    offset="1"
                                    stop-color="#98c220"
                                />
                            </linearGradient>
                        </defs>
                        <title>Prognosix_Zeichen</title>
                        <path
                            class="prognosix-logo-1-st0"
                            d="M537.74,58.09A8.18,8.18,0,0,0,528.6,63,875.94,875.94,0,0,1,385.46,296.64C322.77,310.72,266,339.85,216,375.79q-52.56-5.28-90.12,14.54c-78.16,41.48-75,148.5-74.8,153,0,.17.06.33.08.5s.05.37.09.56a8.07,8.07,0,0,0,.87,2.61c0,.06.09.12.13.18a8.12,8.12,0,0,0,1.83,2.1c.08.06.11.16.19.22s.1,0,.15.08.11.11.17.15a8.17,8.17,0,0,0,1.9.95c.2.07.41.11.62.16a8.07,8.07,0,0,0,1.72.28c.14,0,.27.07.41.07s.26,0,.4,0a10.08,10.08,0,0,0,1.83-.3c.12,0,.24,0,.35-.07,1.88-.62,110-37.85,230.76-139.23,79.25,29.09,166.19,84.76,238.78,138.07a8.16,8.16,0,0,0,13-6.58v-477A8.16,8.16,0,0,0,537.74,58.09ZM528,294.9c-18.69-4.67-60.37-11.78-117.34-3A889.31,889.31,0,0,0,528,104.82ZM133.49,404.75c17.81-9.45,38.26-13.66,60.57-13.66.48,0,1,0,1.47,0-50.11,39.22-92.72,84.53-126.8,126.64C72.46,485.37,85.85,430,133.49,404.75ZM84.77,523.92c36.15-44.16,81.71-91.55,135.38-131a311,311,0,0,1,54.38,12.5C195.38,470.39,122.91,507.1,84.77,523.92Zm204.7-131.08a367.35,367.35,0,0,0-49.94-13.58c38.52-26,80.86-47.47,126.59-60.79A885,885,0,0,1,289.47,392.84Zm17.45,6.39a899.09,899.09,0,0,0,87.27-87.76c68.63-14.37,118.11-3.95,133.79.34V527Q401.75,435.93,306.92,399.23Z"
                            transform="translate(-50.97 -57.93)"
                        />
                    </svg>

                    <span class="text-gray-300 font-semibold text-2xl"> PROGNOSIX AI </span>
                </div>
            </div>

            <div class="app-logo-single">
                <div class="flex">
                    <svg
                        id="Ebene_1"
                        data-name="Ebene 1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 493.33 493.29"
                        style="height: 3rem"
                    >
                        <!-- <defs><style>.prognosix-logo-2-st0{fill:url(#Unbenannter_Verlauf_3);}</style> -->
                        <!-- <defs><style>.prognosix-logo-2-st0{fill:var(--primary-400)}</style> -->
                        <defs>
                            <style>
                                .prognosix-logo-2-st0 {
                                    fill: var(--text-color-secondary);
                                }
                            </style>
                            <linearGradient
                                id="Unbenannter_Verlauf_3"
                                x1="50.97"
                                y1="304.57"
                                x2="544.3"
                                y2="304.57"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop
                                    offset="0"
                                    stop-color="#0d955e"
                                />
                                <stop
                                    offset="0.31"
                                    stop-color="#3ca449"
                                />
                                <stop
                                    offset="0.78"
                                    stop-color="#7eba2c"
                                />
                                <stop
                                    offset="1"
                                    stop-color="#98c220"
                                />
                            </linearGradient>
                        </defs>
                        <title>Prognosix_Zeichen</title>
                        <path
                            class="prognosix-logo-2-st0"
                            d="M537.74,58.09A8.18,8.18,0,0,0,528.6,63,875.94,875.94,0,0,1,385.46,296.64C322.77,310.72,266,339.85,216,375.79q-52.56-5.28-90.12,14.54c-78.16,41.48-75,148.5-74.8,153,0,.17.06.33.08.5s.05.37.09.56a8.07,8.07,0,0,0,.87,2.61c0,.06.09.12.13.18a8.12,8.12,0,0,0,1.83,2.1c.08.06.11.16.19.22s.1,0,.15.08.11.11.17.15a8.17,8.17,0,0,0,1.9.95c.2.07.41.11.62.16a8.07,8.07,0,0,0,1.72.28c.14,0,.27.07.41.07s.26,0,.4,0a10.08,10.08,0,0,0,1.83-.3c.12,0,.24,0,.35-.07,1.88-.62,110-37.85,230.76-139.23,79.25,29.09,166.19,84.76,238.78,138.07a8.16,8.16,0,0,0,13-6.58v-477A8.16,8.16,0,0,0,537.74,58.09ZM528,294.9c-18.69-4.67-60.37-11.78-117.34-3A889.31,889.31,0,0,0,528,104.82ZM133.49,404.75c17.81-9.45,38.26-13.66,60.57-13.66.48,0,1,0,1.47,0-50.11,39.22-92.72,84.53-126.8,126.64C72.46,485.37,85.85,430,133.49,404.75ZM84.77,523.92c36.15-44.16,81.71-91.55,135.38-131a311,311,0,0,1,54.38,12.5C195.38,470.39,122.91,507.1,84.77,523.92Zm204.7-131.08a367.35,367.35,0,0,0-49.94-13.58c38.52-26,80.86-47.47,126.59-60.79A885,885,0,0,1,289.47,392.84Zm17.45,6.39a899.09,899.09,0,0,0,87.27-87.76c68.63-14.37,118.11-3.95,133.79.34V527Q401.75,435.93,306.92,399.23Z"
                            transform="translate(-50.97 -57.93)"
                        />
                    </svg>
                </div>
            </div>
        </a>
    </div>
</div>
