import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    refreshInProgress: boolean = false;

    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // add auth header with jwt if user is logged in and request is to api url

        request = request.clone({
            withCredentials: true,
        });

        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    this.authService.logout();
                }

                if (error.status === 422) {
                    this.authService.logout();
                }
                return throwError(() => new Error(error.message));
            }),
        );
    }
}
