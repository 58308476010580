import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AuthService } from '../core/services/auth.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { User } from '../core/models/user';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    user: BehaviorSubject<User | undefined>;

    userInitials: string = '';

    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    activeItem!: number;
    constructor(
        public layoutService: LayoutService,
        public el: ElementRef,
        private authService: AuthService,
    ) {
        this.user = this.authService.user;
    }

    ngOnInit() {
        this.subscriptions.push(
            this.user.subscribe((user) => {
                if (!user) return;
                this.userInitials = user.first_name[0] + user.last_name[0];
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    logout() {
        this.authService.logout();
    }
}
