import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { LoadingService } from '../services/loading.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
    @Input() loading: BehaviorSubject<boolean>;

    subscriptions: Subscription[] = [];

    constructor(private layoutService: LayoutService) {}

    ngOnInit(): void {
        this.setKeyframes();

        this.subscriptions.push(
            this.layoutService.configUpdate$.subscribe(() => {
                this.setKeyframes();
            }),
        );
    }

    ngOnDestroy(): void {
        // Remove existing style element by ID
        const existingStyle = document.getElementById('dynamic-spinner-style');
        if (existingStyle) {
            existingStyle.remove();
        }

        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    setKeyframes() {
        const primaryColor = getComputedStyle(document.body).getPropertyValue('--primary-color').trim();
        const primaryLightColor = getComputedStyle(document.body).getPropertyValue('--primary-light-color').trim();

        const keyframes = `
        @keyframes custom-progress-spinner-color {
            0%, 100% {
                stroke: ${primaryColor};
            }
            50% {
                stroke: ${primaryLightColor};
            }
        }
    `;

        // Remove existing style element by ID
        const existingStyle = document.getElementById('dynamic-spinner-style');
        if (existingStyle) {
            existingStyle.remove();
        }

        // Create a new style element and set its ID
        const styleElement = document.createElement('style');
        styleElement.id = 'dynamic-spinner-style';
        styleElement.innerHTML = keyframes;
        document.head.appendChild(styleElement);
    }
}
