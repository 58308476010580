<div
    class="layout-container relative"
    [ngClass]="containerClass"
>
    <app-loader [loading]="loading"></app-loader>
    <app-topbar />
    <app-config />
    <div class="layout-content-wrapper">
        <div class="layout-content">
            <app-breadcrumb />
            <div [@routeAnimations]="getRouteAnimationData()">
                <router-outlet/>
            </div>
        </div>
    </div>
    <app-profilemenu />
    <p-toast />

    <div class="layout-mask"></div>
</div>
