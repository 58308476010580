import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.accessToken) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login']);
            return false;
        }

        let user = await this.authService.getUser();
        //const role = route.data['role'];

        if (!user) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login']);
            return false;
        }

        if (
            route.routeConfig?.path != '' &&
            !user.allowedRoutes.includes(route.routeConfig?.path!) &&
            user.homePage != route.routeConfig?.path
        ) {
            this.authService.goToHomePage();
            return false;
        }

        // if (
        //     route.routeConfig?.path != '' &&
        //     !user.allowedRoutes.includes(route.routeConfig?.path!.split('/')[0]!) &&
        //     user.homePage != route.routeConfig?.path
        //     ){
        //   this.authService.goToHomePage();
        //   return false;
        // }

        // if (role && user?.role.name !== role) {
        //   // role not authorised so redirect to home page
        //   this.authService.goToHomePage();
        //   return false;
        // }

        // authorised so return true

        return true;
    }
}
