<div>
    <div class="card">
        <div
            class="relative overflow-hidden h-20rem bg-primary flex flex-column align-items-center justify-content-center border-round mb-8"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                class="absolute w-full top-0 left-0"
            >
                <path
                    fill="var(--primary-600)"
                    fill-opacity="1"
                    d="M0,64L48,90.7C96,117,192,171,288,208C384,245,480,267,576,256C672,245,768,203,864,165.3C960,128,1056,96,1152,74.7C1248,53,1344,43,1392,37.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                ></path>
            </svg>
            <div class="font-bold mb-5 text-4xl z-1">How can we help?</div>
            <span class="p-input-icon-left w-9 md:w-6">
                <i class="pi pi-search"></i>
                <input
                    type="text"
                    pInputText
                    placeholder="Search"
                    style="border-radius: 2rem"
                    class="w-full"
                />
            </span>
        </div>

        <div class="grid mb-5">
            <div class="col-12 md:col-4 mb-5">
                <div class="flex flex-column align-items-center">
                    <span
                        class="inline-flex align-items-center justify-content-center border-circle w-5rem h-5rem bg-primary-100 mb-5"
                    >
                        <i class="pi pi-power-off text-4xl text-primary-700"></i>
                    </span>
                    <div class="text-2xl mb-3 font-medium">Getting Started</div>
                    <ul class="list-none m-0 p-0 text-center">
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Lorem ipsum dolor</a>
                        </li>
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer"
                                >Consectetur adipiscing elit</a
                            >
                        </li>
                        <li class="line-height-3 mb-3">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Sed do eiusmod tempor</a>
                        </li>
                        <li><a class="text-primary hover:underline cursor-pointer font-medium">View all</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 md:col-4 mb-5">
                <div class="flex flex-column align-items-center">
                    <span
                        class="inline-flex align-items-center justify-content-center border-circle w-5rem h-5rem bg-primary-100 mb-5"
                    >
                        <i class="pi pi-arrows-h text-4xl text-primary-700"></i>
                    </span>
                    <div class="text-2xl mb-3 font-medium">Transactions</div>
                    <ul class="list-none m-0 p-0 text-center">
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Lorem ipsum dolor</a>
                        </li>
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer"
                                >Consectetur adipiscing elit</a
                            >
                        </li>
                        <li class="line-height-3 mb-3">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Sed do eiusmod tempor</a>
                        </li>
                        <li><a class="text-primary hover:underline cursor-pointer font-medium">View all</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 md:col-4 mb-5">
                <div class="flex flex-column align-items-center">
                    <span
                        class="inline-flex align-items-center justify-content-center border-circle w-5rem h-5rem bg-primary-100 mb-5"
                    >
                        <i class="pi pi-user text-4xl text-primary-700"></i>
                    </span>
                    <div class="text-2xl mb-3 font-medium">Profile</div>
                    <ul class="list-none m-0 p-0 text-center">
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Lorem ipsum dolor</a>
                        </li>
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer"
                                >Consectetur adipiscing elit</a
                            >
                        </li>
                        <li class="line-height-3 mb-3">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Sed do eiusmod tempor</a>
                        </li>
                        <li><a class="text-primary hover:underline cursor-pointer font-medium">View all</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 md:col-4 mb-5">
                <div class="flex flex-column align-items-center">
                    <span
                        class="inline-flex align-items-center justify-content-center border-circle w-5rem h-5rem bg-primary-100 mb-5"
                    >
                        <i class="pi pi-money-bill text-4xl text-primary-700"></i>
                    </span>
                    <div class="text-2xl mb-3 font-medium">Billing</div>
                    <ul class="list-none m-0 p-0 text-center">
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Lorem ipsum dolor</a>
                        </li>
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer"
                                >Consectetur adipiscing elit</a
                            >
                        </li>
                        <li class="line-height-3 mb-3">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Sed do eiusmod tempor</a>
                        </li>
                        <li><a class="text-primary hover:underline cursor-pointer font-medium">View all</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 md:col-4 mb-5">
                <div class="flex flex-column align-items-center">
                    <span
                        class="inline-flex align-items-center justify-content-center border-circle w-5rem h-5rem bg-primary-100 mb-5"
                    >
                        <i class="pi pi-database text-4xl text-primary-700"></i>
                    </span>
                    <div class="text-2xl mb-3 font-medium">Integrations</div>
                    <ul class="list-none m-0 p-0 text-center">
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Lorem ipsum dolor</a>
                        </li>
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer"
                                >Consectetur adipiscing elit</a
                            >
                        </li>
                        <li class="line-height-3 mb-3">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Sed do eiusmod tempor</a>
                        </li>
                        <li><a class="text-primary hover:underline cursor-pointer font-medium">View all</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 md:col-4 mb-5">
                <div class="flex flex-column align-items-center">
                    <span
                        class="inline-flex align-items-center justify-content-center border-circle w-5rem h-5rem bg-primary-100 mb-5"
                    >
                        <i class="pi pi-lock text-4xl text-primary-700"></i>
                    </span>
                    <div class="text-2xl mb-3 font-medium">Security</div>
                    <ul class="list-none m-0 p-0 text-center">
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Lorem ipsum dolor</a>
                        </li>
                        <li class="line-height-3 mb-1">
                            <a class="text-color-secondary hover:text-primary cursor-pointer"
                                >Consectetur adipiscing elit</a
                            >
                        </li>
                        <li class="line-height-3 mb-3">
                            <a class="text-color-secondary hover:text-primary cursor-pointer">Sed do eiusmod tempor</a>
                        </li>
                        <li><a class="text-primary hover:underline cursor-pointer font-medium">View all</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
