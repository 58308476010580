<div class="surface-0">
    <div class="flex align-items-center justify-content-between flex-column h-screen">
        <div
            class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-4"
        >
            <a
                [routerLink]="['/']"
                class="mb-6"
            >
                <svg
                    height="56"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 0H6.00019V3.82345L17 1.66667V6.66667L6.00019 8.82345V10.4901L17 8.33333V13.3333L6.00019 15.4901V20H0V0Z"
                        fill="url(#paint0_linear)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear"
                            x1="3.33335"
                            y1="3.08442e-08"
                            x2="8.49995"
                            y2="20"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="var(--primary-400)" />
                            <stop
                                offset="1"
                                stop-color="var(--primary-700)"
                            />
                        </linearGradient>
                    </defs>
                </svg>
            </a>
            <div class="mb-4">
                <div class="text-900 text-xl font-bold mb-2">Register</div>
                <span class="text-600 font-medium">Let's get started</span>
            </div>
            <div class="flex flex-column">
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-user"></i>
                    <input
                        id="username"
                        type="text"
                        pInputText
                        class="w-full md:w-25rem text-color-secondary surface-50 border-200"
                        placeholder="Username"
                    />
                </span>
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-envelope"></i>
                    <input
                        id="email"
                        type="text"
                        pInputText
                        class="w-full md:w-25rem text-color-secondary surface-50 border-200"
                        placeholder="Email"
                    />
                </span>
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-lock z-2"></i>
                    <p-password
                        id="password"
                        placeholder="Password"
                        [inputStyle]="{ paddingLeft: '2.5rem' }"
                        inputStyleClass="w-full md:w-25rem text-color-secondary surface-50 border-200"
                        [toggleMask]="true"
                    ></p-password>
                </span>
                <div class="mb-4 flex flex-wrap">
                    <p-checkbox
                        name="checkbox"
                        value="val"
                        [(ngModel)]="confirmed"
                        styleClass="mr-2"
                        [binary]="true"
                    ></p-checkbox>
                    <label
                        for="checkbox"
                        class="text-900 font-medium mr-2"
                        >I have read the</label
                    >
                    <a class="text-600 cursor-pointer hover:text-primary cursor-pointer">Terms and Conditions</a>
                </div>
                <button
                    pButton
                    pRipple
                    label="Sign Up"
                    class="w-full mb-4"
                    [routerLink]="['/']"
                ></button>
                <span class="font-medium text-600"
                    >Already have an account?
                    <a
                        class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300"
                        >Login</a
                    ></span
                >
            </div>
        </div>
        <div class="flex flex-wrap align-items-center pb-8 px-4">
            <h4
                class="m-0 mr-5"
                style="line-height: 22px"
            >
                freya
            </h4>
            <h6
                class="m-0 font-medium text-300"
                style="line-height: 17px"
            >
                Copyright Ⓒ PrimeTek Informatics
            </h6>
        </div>
    </div>
</div>

<app-config [minimal]="true"></app-config>
