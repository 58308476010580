<div
    *ngIf="loading"
    class="loader-background"
>
    <div class="w-6">
        <p-progressBar
            [value]="value"
            [showValue]="false"
        ></p-progressBar>
    </div>

    <div class="mt-1 text-animated">{{ value }}%</div>

    <div class="mt-1 text-animated">
        {{ shownPhrase }}
    </div>
</div>
